import styled from '@emotion/styled';

const Bullet = styled('span')({
  fontSize: '2.2rem',
  fontWeight: 'bold',
  lineHeight: '1.8rem',
  paddingRight: '0.2rem',
  verticalAlign: 'middle',
});

export default Bullet;
