import styled from '@emotion/styled';

const ArtCellPodcast = styled('td')(({ theme }) => ({
  button: {
    height: '3rem',
    position: 'relative',
    width: '3rem',
    zIndex: 1,
  },
  svg: {
    fill: theme.colors.gray.dark,
  },
}));

export default ArtCellPodcast;
