import styled from '@emotion/styled';

const PodcastCircleProgressContainer = styled('div')({
  height: '3.2rem',
  left: '-0.2rem',
  position: 'absolute',
  top: '-0.2rem',
  width: '3.4rem',
});

export default PodcastCircleProgressContainer;
