import styled from '@emotion/styled';

const PodcastMetadata = styled('p')(({ theme }) => ({
  color: theme.colors.gray.medium,
  fontSize: theme.fonts.size.xsmall,
  lineHeight: '1.8rem',
  a: {
    color: theme.colors.gray.medium,
  },
}));

export default PodcastMetadata;
