import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import TopSongs from './TopSongs';
import {
  albumOverflowsSelector,
  getCurrentArtistId,
  getCurrentArtistName,
  getCurrentArtistStationId,
  getCurrentArtistTopTracks,
} from 'state/Artists/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getAmpUrl } from 'state/Config/selectors';
import { getCurrentPath } from 'state/Routing/selectors';
import { getCustomRadioEnabled } from 'state/Features/selectors';
import { getTracksThumbsSentiments } from 'state/Tracks/selectors';
import { localize } from 'redux-i18n';
import { showAlbumHeaderSelector } from 'state/Entitlements/selectors';
import { updateThumbsData } from 'state/Stations/actions';

const mapStateToProps = createStructuredSelector({
  ampUrl: getAmpUrl,
  artistId: getCurrentArtistId,
  artistName: getCurrentArtistName,
  customRadioEnabled: getCustomRadioEnabled,
  overflowEntitlements: albumOverflowsSelector,
  showAlbumHeader: showAlbumHeaderSelector,
  stationId: getCurrentArtistStationId,
  thumbs: getTracksThumbsSentiments,
  tracks: getCurrentArtistTopTracks,
  url: getCurrentPath,
});

export default localize('translate')(
  connect(mapStateToProps, { updateThumbsData })(PlayerStateProxy(TopSongs)),
);
