import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const DurationPodcast = styled('span')(({ theme }) => ({
  color: theme.colors.gray.medium,
  fontSize: theme.fonts.size.xsmall,
  lineHeight: '1.8rem',
  marginRight: '2rem',
  minWidth: '6rem',

  a: {
    color: theme.colors.gray.medium,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'none',
  },
}));

export default DurationPodcast;
