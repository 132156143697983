import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';
import { Menu } from 'components/Tooltip';

type Props = {
  showThumbs?: boolean;
};

const RowDropdown = styled(Menu)<Props>(({ showThumbs = false, theme }) => ({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  marginLeft: showThumbs ? 0 : '2rem',
  zIndex: theme.zIndex.trackRowDropdown,

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginLeft: '1.5rem',
  },
}));

export default RowDropdown;
