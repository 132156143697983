import styled from '@emotion/styled';

const TotalSongsWrapper = styled('li')(({ theme }) => ({
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: '1.3rem',
  lineHeight: '1.8rem',
  verticalAlign: 'middle',
}));

export default TotalSongsWrapper;
