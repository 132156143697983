import classnames from 'classnames';
import NavLink from 'components/NavLink';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { noop } from 'lodash-es';
import { truncate, truncateHtml } from 'utils/string';
import { useState } from 'react';

export type Props = {
  classes?: Array<string>;
  className?: string;
  isHtml?: boolean;
  itemProp: string;
  maxChar: number;
  onClick?: () => void;
  text: string;
};

function ExpandableText({
  classes = [],
  className,
  isHtml,
  itemProp,
  maxChar,
  onClick = noop,
  text,
}: Props) {
  const translate = useTranslate();

  const combinedClass = classnames(classes, className);
  const [expanded, setExpanded] = useState<boolean>(false);

  function handleOnClick(): void {
    setExpanded(true);
    onClick();
  }

  if (!text) return <div />;

  const { textLen, truncatedText } =
    isHtml ?
      truncateHtml(text, maxChar)
    : { textLen: text.length, truncatedText: truncate(text, maxChar) };

  if (expanded || textLen < maxChar) {
    return (
      <div
        className={combinedClass}
        dangerouslySetInnerHTML={{ __html: text }}
        itemProp={itemProp}
      />
    );
  }

  return (
    <div className={combinedClass} itemProp={itemProp}>
      <div dangerouslySetInnerHTML={{ __html: truncatedText }} />{' '}
      <NavLink onClick={handleOnClick}> {translate('Read more')}</NavLink>
    </div>
  );
}

export default ExpandableText;
