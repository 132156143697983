import styled from '@emotion/styled';

const List = styled('ul')({
  listStyle: 'none',
  margin: 0,
  marginTop: '1.5rem',
  padding: 0,
  verticalAlign: 'middle',
  width: '100%',
});

export default List;
