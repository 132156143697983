import ArtistHero from './ArtistHero';
import {
  backgroundColor,
  noLogo,
  primaryBackgroundSrc,
} from 'state/Hero/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentArtistId,
  getCurrentArtistIsFavorite,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import { getCustomRadioEnabled } from 'state/Features/selectors';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import { getSubscriptionType } from 'state/Entitlements/selectors';
import { localize } from 'redux-i18n';
import {
  resetHero,
  setHasHero,
  setHeroPremiumBackground,
} from 'state/Hero/actions';
import { showFavoriteChangedGrowl } from 'state/UI/actions';
import { toggleFavoriteArtist } from 'state/Artists/actions';

export default compose(
  localize('translate'),
  connect(
    createStructuredSelector({
      artistName: getCurrentArtistName,
      backgroundColor,
      customRadioEnabled: getCustomRadioEnabled,
      isFavorite: getCurrentArtistIsFavorite,
      mediaServerUrl: getMediaServerUrl,
      noLogo,
      primaryBackgroundSrc,
      seedId: getCurrentArtistId,
      siteUrl: getSiteUrl,
    }),
    {
      resetHero,
      setHasHero,
      showFavoriteChangedGrowl,
      subscriptionType: getSubscriptionType,
      toggleFavoriteArtist,
      setHeroPremiumBackground,
    },
  ),
)(ArtistHero);
