import styled from '@emotion/styled';

const AlbumReleaseInfo = styled('li')(({ theme }) => ({
  color: theme.colors.gray.medium,
  display: 'inline-block',
  fontSize: '1.3rem',
  lineHeight: '1.8rem',
  paddingRight: '.9rem',
}));

export default AlbumReleaseInfo;
