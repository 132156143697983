export default function getNextUnplayedTrack(episodes, currentTrackId) {
  const trackIndex = episodes.findIndex(e => e.id === currentTrackId);
  const playlist = [
    ...episodes.slice(trackIndex + 1),
    ...episodes.slice(0, trackIndex),
  ];
  return playlist.find(
    e => !e.secondsPlayed || e.secondsPlayed / e.duration < 0.95,
  );
}
