import styled from '@emotion/styled';

const PlayButtonWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  background: theme.colors.transparent.dark,
  cursor: 'pointer',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  transition: 'opacity 0.3s',
  width: '100%',

  '&:hover': {
    opacity: 1,
  },

  '.play': {
    backgroundColor: theme.colors.transparent.primary,
    fill: theme.colors.white.primary,
    height: '50%',
    width: '50%',

    '&:hover': {
      borderColor: 'rgba(119, 119, 119, 0.7)',
    },
  },
}));

export default PlayButtonWrapper;
