import SectionTemplate from 'components/Section';
import styled from '@emotion/styled';

const Section = styled(SectionTemplate)(({ theme }) => ({
  '.type-label': {
    color: theme.colors.red.primary,
    fontSize: '1.4rem',
    letterSpacing: '0.1rem',
    lineHeight: '1.755rem',
    textTransform: 'uppercase',
  },
}));

export default Section;
