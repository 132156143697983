import styled from '@emotion/styled';

const Mask = styled('div')({
  background: 'hsla(0, 0%, 9%, 0.75)',
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
});

export default Mask;
