import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  showMoreTile?: boolean;
};

const ItemTitle = styled('div')<Props>(({ theme, showMoreTile = false }) => ({
  alignSelf: 'center',
  color: theme.colors.black.secondary,
  fontSize: '2.2rem',
  lineHeight: 1.35,
  padding: '0 0.75rem 0 1.5rem',
  textDecoration: 'none',
  width: !showMoreTile ? 'calc(50% - 1.5rem)' : 'inherit',

  [mediaQueryBuilder([
    theme.mediaQueries.min.width['768'],
    theme.mediaQueries.max.width['1160'],
  ])]: {
    margin: 0,
    width: '100%',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['1024'])]: {
    fontSize: '1.6rem',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    marginBottom: '1.5rem',
    padding: '1.5rem 0 0',
    width: '100%',
  },
}));

export default ItemTitle;
