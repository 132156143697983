import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ImageWrapper = styled('div')(({ theme }) => ({
  WebkitMaskImage: '-webkit-radial-gradient(white, black)', // safari specific issue with overflow and border radius
  borderRadius: '0.8rem',
  marginRight: '1.5rem',
  maxHeight: '20rem',
  overflow: 'hidden',
  width: 'calc(50% - 1.5rem)',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    margin: 0,
    maxHeight: 'none',
    width: '100%',
  },
}));

export default ImageWrapper;
