import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlaylistTableRow from 'shared/ui/components/PlaylistTableRow';
import Section from 'components/Section';
import { get } from 'lodash-es';
import { getTrackUrl } from 'utils/url';
import { PureComponent } from 'react';
import { STATION_TYPE } from 'constants/stationTypes';

export default class ArtistTopSongs extends PureComponent {
  static defaultProps = { limit: 5 };

  updateThumbs = ({ sentiment, trackId }, existingSentiment, track) => {
    const { artistId, artistName, stationId, updateThumbsData } = this.props;

    updateThumbsData({
      existingSentiment,
      seedId: artistId,
      sentiment,
      stationId,
      stationType: STATION_TYPE.ARTIST,
      trackId,
      trackingData: {
        id: artistId,
        itemId: artistId,
        itemName: artistName,
        name: artistName,
        songId: trackId,
        songName: track.name,
        type: 'artist',
      },
    });
  };

  render() {
    const {
      ampUrl,
      currentlyPlaying,
      playingState,
      translate,
      customRadioEnabled,
      artistId,
      artistName,
      tracks,
      limit,
      url,
      showAlbumHeader,
      overflowEntitlements,
      onAddToMyMusic,
      onAddToPlaylist,
      thumbs,
    } = this.props;
    const songsUrl = `${url}songs`;

    const rows = (
      <tbody>
        {tracks.slice(0, limit).map((track, i) => {
          const sentiment = get(thumbs, track.trackId);

          return (
            <PlaylistTableRow
              ampUrl={ampUrl}
              artistId={artistId}
              artistName={artistName}
              canPlaylist={showAlbumHeader}
              currentlyPlaying={currentlyPlaying}
              description={artistName}
              explicitLyrics={!!track.explicitLyrics}
              fetchLyrics
              hideDescription
              isOnDemandTrack={
                !!track.playbackRights && track.playbackRights.onDemand
              }
              key={track.trackId}
              onAddToMyMusic={onAddToMyMusic}
              onAddToPlaylist={onAddToPlaylist}
              overflowEntitlements={overflowEntitlements}
              playable={customRadioEnabled}
              playedFrom={PLAYED_FROM.PROF_ARTIST_MAIN_SONGS}
              playingState={playingState}
              seedId={track.trackId}
              seedType={STATION_TYPE.TRACK}
              sentiment={sentiment}
              stationType={STATION_TYPE.ARTIST}
              title={track.title}
              titleUrl={getTrackUrl(
                artistId,
                artistName,
                track.trackId,
                track.name,
              )}
              trackCount={i + 1}
              trackId={track.trackId}
              trackThumb={track.image}
              updateThumbs={props => this.updateThumbs(props, sentiment, track)}
            />
          );
        })}
      </tbody>
    );

    if (!tracks.length) {
      return null;
    }

    return (
      <Section
        dataTest="section-topsongs"
        emptyMessage={translate("This artist doesn't have any top songs.")}
        header={translate('Top Songs')}
        isEmpty={!tracks.length}
        url={songsUrl}
      >
        <table>{rows}</table>
      </Section>
    );
  }
}
