import CatalogImage from 'components/MediaServerImage/CatalogImage';
import Dropdown from 'components/Dropdown/LiveDropdown';
import LiveDescription from 'components/LiveDescription';
import NavLink from 'components/NavLink';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import Section from 'components/Section';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import { getCurrentArtistPopularOn } from 'state/Artists/selectors';
import { slugify } from 'utils/string';
import { STATION_TYPE } from 'constants/stationTypes';
import { TILE_RES } from 'components/MediaServerImage';
import { useSelector } from 'react-redux';
import { useTranslate } from 'widget/hooks';

const PlayButton = PlayerStateProxy(PlayButtonContainer);

export default function PopularOn({
  singleRow,
  tilesInRow,
}: {
  singleRow?: boolean;
  tilesInRow: number;
}) {
  const stations = useSelector(getCurrentArtistPopularOn);
  const translate = useTranslate();

  return (
    <Section
      dataTest="section-popular-on"
      header={translate('Popular On')}
      isHidden={!stations.length}
    >
      <Tiles tilesInRow={tilesInRow}>
        {stations.map(
          ({ description, stationName: name, stationId: seedId, image }) => {
            const imgWidth = TILE_RES;
            const seedType = STATION_TYPE.LIVE;
            const url = `/live/${slugify(name)}-${seedId}/`;
            return (
              <Tile
                dropdown={
                  <Dropdown
                    key={`station-${seedId}`}
                    name={name}
                    seedId={seedId}
                    seedType={seedType}
                  />
                }
                key={`popularOn|${seedId}`}
                singleRow={singleRow}
                subTitle={<LiveDescription text={description} />}
                tilesInRow={tilesInRow}
                title={name}
                url={url}
              >
                <NavLink
                  css={{ display: 'block', position: 'relative' }}
                  to={url}
                >
                  <PlayButtonContainerPrimitive>
                    <PlayButton
                      className="play"
                      deferPlay={!!url}
                      playedFrom={PLAYED_FROM.PROF_ARTIST_MAIN_POPULAR_ON}
                      seedId={seedId}
                      stationId={seedId}
                      stationType={seedType}
                    />
                  </PlayButtonContainerPrimitive>
                  <TilesImageWrapper liveTile={seedType === STATION_TYPE.LIVE}>
                    <CatalogImage
                      alt={name}
                      aspectRatio={1}
                      height={imgWidth}
                      id={seedId}
                      src={image}
                      type={seedType}
                      width={imgWidth}
                    />
                  </TilesImageWrapper>
                </NavLink>
              </Tile>
            );
          },
        )}
      </Tiles>
    </Section>
  );
}
