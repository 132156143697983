import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';

export const ellipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 'calc(100% - 8.5rem)',

  [mediaQueryBuilder(mediaQueries.max.width['768'])]: {
    width: 'calc(100% - 1.5rem)',
  },
};
