import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = { newHero?: boolean };

const FollowButtonWrapper = styled.div<Props>(({ newHero = false, theme }) => ({
  margin: 'auto 0',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    paddingTop: newHero ? 0 : '0.5rem',
  },
}));

export default FollowButtonWrapper;
