import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const AlbumMeta = styled('ul')(({ theme }) => ({
  display: 'inline-block',
  listStyle: 'none',
  padding: 0,
  verticalAlign: 'middle',
  width: 'calc(100% - 3.8rem)',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: 'calc(100% - 3.2rem)',
  },
}));

export default AlbumMeta;
