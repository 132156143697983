import styled from '@emotion/styled';

const AlbumInfo = styled('div')({
  alignItems: 'center',
  display: 'inline-flex',
  padding: '0 0 1.5rem',
  textAlign: 'left',
  width: 'calc(74% - 1.5rem)',
});

export default AlbumInfo;
