import countryCodes from 'constants/countryCodes';
import NavLink from 'components/NavLink';
import saveAlbumAction from 'state/YourLibrary/saveAlbum';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { addAlbumToPlaylist } from 'state/Playlist/actions';
import { getCountryCode } from 'state/Config/selectors';
import { getCurrentArtistLatestRelease } from 'state/Artists/selectors';
import { getUserType } from 'state/User/selectors';
import { GrowlIcons } from 'components/Growls/constants';
import { Menu } from 'components/Tooltip';
import {
  SaveDeleteComponent,
  SaveDeleteView,
} from 'modules/Analytics/helpers/saveDelete';
import { showNotifyGrowl } from 'state/UI/actions';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptionConstants';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { FunctionComponent } from 'react';

const LatestReleaseOverflow: FunctionComponent = () => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const { albumId } = useSelector(getCurrentArtistLatestRelease) ?? {};

  const countryCode = useSelector(getCountryCode);
  const userType = useSelector(getUserType);
  const showSave = userType === SUBSCRIPTION_TYPE.PREMIUM;

  const enableAddToPlaylist =
    countryCode === countryCodes.US || countryCode === countryCodes.CA;
  /**
   * Handles click behavior for Add To Playlist Option
   */
  const addToPlaylist = useCallback(() => {
    dispatch(
      addAlbumToPlaylist({
        albumId,
        view: SaveDeleteView.ArtistProfile,
        component: SaveDeleteComponent.ListAlbumsOverflow,
      }),
    );
  }, [albumId, dispatch]);

  const saveAlbum = useCallback(() => {
    // Happy path for premium user (Save Album)
    dispatch(saveAlbumAction.action(albumId));
    return dispatch(
      showNotifyGrowl({
        icon: GrowlIcons.CheckCircle,
        title: translate('Album saved to Your Library'),
      }),
    );
  }, [albumId, dispatch, translate]);

  if (!showSave && !enableAddToPlaylist) return null;

  const addToPlaylistText = translate('Add to Playlist');
  const saveAlbumText = translate('Save Album');

  return (
    <Menu>
      <Menu.List>
        {showSave && (
          <Menu.Item>
            <NavLink onClick={saveAlbum} title={saveAlbumText}>
              {saveAlbumText}
            </NavLink>
          </Menu.Item>
        )}
        {enableAddToPlaylist && (
          <Menu.Item>
            <NavLink onClick={addToPlaylist} title={addToPlaylistText}>
              {addToPlaylistText}
            </NavLink>
          </Menu.Item>
        )}
      </Menu.List>
    </Menu>
  );
};

export default LatestReleaseOverflow;
