import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const ListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  paddingBottom: '1.5rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    flexDirection: 'column',
  },
}));

export default ListItem;
