import styled from '@emotion/styled';

type Props = {
  playButtonNumber: boolean;
};

const Hover = styled('div')<Props>(({ playButtonNumber }) => ({
  ...(playButtonNumber ?
    {}
  : {
      '&:hover': {
        opacity: 1,
      },
      alignItems: 'center',
      bottom: 0,
      display: 'flex',
      left: 0,
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    }),
}));

export default Hover;
