import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const TrackCellPodcast = styled('td')(({ theme }) => ({
  ...theme.mixins.ellipsis,
  paddingLeft: '1.5rem',
  position: 'relative',
  width: '100%',

  span: {
    [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
      float: 'left',
    },
  },
}));

export default TrackCellPodcast;
