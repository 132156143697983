import { IGetTranslateFunctionResponse } from 'redux-i18n';

type Props = {
  markAsPlayed: () => void;
  markAsUnplayed: () => void;
  trackHasBeenPlayed: boolean;
  translate: IGetTranslateFunctionResponse;
};

const MarkAsPlayedToggle = ({
  markAsPlayed,
  markAsUnplayed,
  trackHasBeenPlayed,
  translate,
}: Props) => {
  const toggleProgress = () => {
    if (trackHasBeenPlayed) markAsUnplayed();
    else markAsPlayed();
  };

  return (
    <a onClick={toggleProgress}>
      {trackHasBeenPlayed ?
        translate('Mark As Unplayed')
      : translate('Mark as Played')}
    </a>
  );
};

export default MarkAsPlayedToggle;
