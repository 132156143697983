import styled from '@emotion/styled';

const AlbumArtWrapper = styled('div')({
  display: 'inline-block',
  margin: '0 1.5rem 0 0 ',
  position: 'relative',
  verticalAlign: 'middle',
  width: '26%',
});

export default AlbumArtWrapper;
