import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const sideMargin = 0.7;

const ArtistPictureWrapper = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  boxShadow: '0 0.1rem 0.3rem rgba(0, 0, 0, 0.15)',
  display: 'inline-block',
  margin: `0 ${sideMargin}rem`,
  marginBottom: '1rem',
  overflow: 'hidden',
  position: 'relative',
  width: `calc(50% - ${sideMargin * 2}rem)`,

  [mediaQueryBuilder(theme.mediaQueries.max.width[599])]: {
    width: `calc(100% - ${sideMargin * 2}rem)`,
  },
}));

export default ArtistPictureWrapper;
