import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const TrackActions = styled('td')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'right',
  width: '15.2rem',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    width: '5rem',
  },
}));

export default TrackActions;
