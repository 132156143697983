import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const DurationPodcastMobile = styled('span')(({ theme }) => ({
  display: 'none',
  float: 'right',
  position: 'relative',

  '&:after': {
    content: `''`,
    fontSize: '2rem',
    left: '-1.3rem',
    position: 'absolute',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    display: 'block',
    float: 'left',
    marginLeft: '2rem',

    '&:after': {
      content: `'•'`,
    },
  },
}));

export default DurationPodcastMobile;
