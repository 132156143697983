import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import PlaylistTableRow from './PlaylistTableRow';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { flowRight } from 'lodash-es';
import { getAppMounted } from 'state/UI/selectors';
import { getCountryCode, getStationSoftgate } from 'state/Config/selectors';
import {
  getCustomRadioEnabled,
  getFreeUserMyPlaylistEnabled,
  getInternationalPlaylistRadioEnabled,
} from 'state/Features/selectors';
import { getIsLoggedOut } from 'state/Session/selectors';
import { localize } from 'redux-i18n';
import { openSignupModal } from 'state/UI/actions';
import { State } from 'state/buildInitialState';
import { StationSoftgate } from 'state/Config/types';

export default flowRight(
  localize('translate'),
  connect(
    createStructuredSelector<
      State,
      {
        appMounted: boolean;
        isCustomRadioEnabled: boolean;
        countryCode: string;
        isLoggedOut: boolean;
        isFreeMyPlaylistEnabled: boolean;
        isInternationalPlaylistRadioEnabled: boolean;
        stationSoftgate: StationSoftgate;
      }
    >({
      appMounted: getAppMounted,
      countryCode: getCountryCode,
      isCustomRadioEnabled: getCustomRadioEnabled,
      isFreeMyPlaylistEnabled: getFreeUserMyPlaylistEnabled,
      isInternationalPlaylistRadioEnabled: getInternationalPlaylistRadioEnabled,
      isLoggedOut: getIsLoggedOut,
      stationSoftgate: getStationSoftgate,
    }),
    {
      openSignup: () => openSignupModal({ context: 'reg-gate' }),
    },
  ),
  PlayerStateProxy,
)(PlaylistTableRow);
