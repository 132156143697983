import hub, { E } from 'shared/utils/Hub';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import PlayerState from 'web-player/PlayerState';
import PlaylistTypes from 'constants/playlistTypes';
import qs from 'qs';
import reduxFactory from 'state/factory';
import url from 'url';
import whenPopulated from 'utils/whenPopulated';
import { createRadio } from 'shims/playbackActions';
import { get, omit } from 'lodash-es';
import { getCurrentEpisodeId } from 'state/Routing/selectors';
import { getCurrentPodcastEpisodes } from 'state/Podcast/selectors';
import { getIsMobile } from 'state/Environment/selectors';
import { STATION_TYPE } from 'constants/stationTypes';

const playerState = PlayerState.getInstance();
const store = reduxFactory();

async function autoplay(props) {
  const { seedId, seedType } = props;
  if (!seedId || !seedType) return;

  const urlObj = url.parse(window.location.href);
  const query = qs.parse(urlObj.query || {});
  let playedFrom = null;
  let mediaId = null;

  if (query.autoplay) {
    const newQuery = qs.stringify(omit(query, 'autoplay'));
    const newUrl = urlObj.resolveObject({
      search: newQuery ? `?${newQuery}` : '',
    }).href;
    window.history.pushState({}, '', newUrl);

    if (query.playedFrom) {
      playedFrom = parseInt(query.playedFrom, 10) || PLAYED_FROM.PLAYER_PLAY;
    } else {
      switch (props.seedType) {
        case STATION_TYPE.LIVE:
          playedFrom = PLAYED_FROM.PROF_LIVE_AUTOPLAY;
          break;
        case STATION_TYPE.ALBUM:
          playedFrom = PLAYED_FROM.PROF_ALBUM_AUTOPLAY;
          break;
        case STATION_TYPE.ARTIST:
          playedFrom = PLAYED_FROM.PROF_ARTIST_AUTOPLAY;
          break;
        case STATION_TYPE.FAVORITES:
          playedFrom = PLAYED_FROM.PROF_FAVORITES_AUTOPLAY;
          break;
        case STATION_TYPE.TRACK:
          playedFrom = PLAYED_FROM.PROF_SONG_AUTOPLAY;
          break;
        case STATION_TYPE.PODCAST: {
          playedFrom = PLAYED_FROM.PROF_EPISODE_AUTOPLAY;
          mediaId =
            getCurrentEpisodeId(store.getState()) ||
            get(
              await whenPopulated(
                store,
                getCurrentPodcastEpisodes,
                data => data.length > 0,
              ),
              '[0].id',
              '',
            );
          break;
        }
        case STATION_TYPE.COLLECTION:
          playedFrom =
            props.playlistType === PlaylistTypes.New4U ?
              PLAYED_FROM.PROF_NEW4U_AUTOPLAY
            : PLAYED_FROM.PROF_PLAYLIST_AUTOPLAY;
          break;
        case STATION_TYPE.PLAYLIST_RADIO:
          playedFrom =
            props.playlistType === PlaylistTypes.New4U ?
              PLAYED_FROM.PROF_NEW4U_RADIO_AUTOPLAY
            : PLAYED_FROM.PROF_PLAYLIST_RADIO_AUTOPLAY;
          break;
        case STATION_TYPE.MY_MUSIC:
          playedFrom = PLAYED_FROM.PROF_MY_SONGS_SONGS;
          break;
        default:
          playedFrom = undefined;
      }
    }
    createRadio({
      mediaId,
      playedFrom,
      stationId: seedId,
      stationType: seedType,
    });
  }
}

export default function checkAutoplay(props) {
  if (getIsMobile(store.getState())) return;
  if (props.seedType === STATION_TYPE.ARTIST && !props.customRadioEnabled)
    return;

  playerState.set('streamInitTime', Date.now());

  if (!playerState.get('inInit')) {
    autoplay(props);
  } else {
    // TODO: this isn't set up to work with new ads and the callback will never run
    // IHRWEB-17967
    hub.once(E.AD_WARMUP_COMPLETE, () => {
      autoplay(props);
    });
  }
}
