import styled from '@emotion/styled';

const TrackNumberCell = styled('td')(({ theme }) => ({
  color: theme.colors.gray.medium,
  flex: '0 1 auto',
  fontSize: theme.fonts.size.xsmall,
  width: '2rem',
}));

export default TrackNumberCell;
