import styled from '@emotion/styled';
import { ellipsis } from '../styleMixins';

const ArtistCell = styled('td')({
  flex: '0 1 auto',
  maxWidth: '10rem',
  minWidth: '8rem',

  ...ellipsis,

  a: {
    fontSize: '1.3rem',
    lineHeight: '1.8rem',
  },
});

export default ArtistCell;
