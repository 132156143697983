import ArtistPictureWrapper from './primitives/ArtistPictureWrapper';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import ExpandableText from 'components/ExpandableText';
import ParagraphTitle from './primitives/ParagraphTitle';
import Section from 'components/Section';
import TextWrapper from './primitives/TextWrapper';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import {
  getCurrentArtistId,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import { STATION_TYPE } from 'constants/stationTypes';
import { useSelector } from 'react-redux';

type Props = {
  bio?: string;
};

export default function ArtistBio({ bio }: Props) {
  const artistId = useSelector(getCurrentArtistId);
  const artistName = useSelector(getCurrentArtistName);
  const translate = useTranslate();

  if (!bio) return null;

  return (
    <Section dataTest="section-artist-bio" header={translate('About')}>
      <div style={{ overflow: 'auto' }}>
        <ArtistPictureWrapper>
          <CatalogImage
            alt={artistName}
            aspectRatio={1}
            id={artistId}
            type={STATION_TYPE.ARTIST}
            width={360}
          />
        </ArtistPictureWrapper>
        <TextWrapper>
          <ParagraphTitle>{translate('Biography')}</ParagraphTitle>
          <ExpandableText itemProp="description" maxChar={583} text={bio} />
        </TextWrapper>
      </div>
    </Section>
  );
}
