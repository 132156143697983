import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const TrackCellDefault = styled('td')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 auto',
  justifyContent: 'flex-end',
  // 100% - numeration - artImage - trackActions - padding
  maxWidth: 'calc(100% - 2rem - 4.5rem - 15.2rem - 3rem)',
  padding: '1.5rem',
  position: 'relative',
  width: '100%',

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    maxWidth: '100%',
    // 100% - numeration - artImage - trackActions
    width: 'calc(100% - 2rem - 4.5rem - 5rem)',
  },

  a: {
    ...theme.mixins.ellipsis,
    width: '100%',
  },
}));

export default TrackCellDefault;
