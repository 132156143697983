import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const DescriptionWrapper = styled.div(({ theme }) => ({
  color: theme.colors.white.primary,
  fontSize: '1.6rem',
  lineHeight: '2.5rem',
  paddingRight: '1.5rem',
  width: '44rem',

  'a, p': {
    color: theme.colors.white.primary,
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['768'])]: {
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    width: '100%',
  },
}));

export default DescriptionWrapper;
