import styled from '@emotion/styled';

const PlayButtonWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.white.primary,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
  '& > button': {
    height: '2.25rem',
    margin: '0 auto',
    width: '2.25rem',
  },
}));

export default PlayButtonWrapper;
