import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const TextWrapper = styled('div')(({ theme }) => ({
  float: 'left',
  fontSize: theme.fonts.size['14'],
  lineHeight: theme.fonts.lineHeight['18'],
  paddingRight: theme.dimensions.gutter,
  width: '50%',

  [mediaQueryBuilder(theme.mediaQueries.max.width[599])]: {
    width: '100%',
  },

  'a, button': {
    fontWeight: theme.fonts.weight.bold,
  },
}));

export default TextWrapper;
