import styled from '@emotion/styled';

type Props = {
  isOnDemand: boolean;
};

const PlayButtonNumberCell = styled('td')<Props>(({ isOnDemand, theme }) => ({
  ...(isOnDemand ?
    {}
  : {
      color: theme.colors.gray.medium,
      fontSize: theme.fonts.size.xsmall,
      padding: '1.3rem',
      position: 'relative',
      visibility: 'visible',
    }),
}));

export default PlayButtonNumberCell;
